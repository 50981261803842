import React from 'react'
import { motion } from 'framer-motion';
import Truck from "../assets/Truck.jpeg"

export const Companyinfo = () => {
  // Animation variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };
  const slideInLeft = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
  };

  return (
    <div>
      <section>
        <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8 text-center">
          <div className="grid grid-cols-1 lg:h-screen lg:grid-cols-2">
            <motion.div className="relative z-10 lg:py-16" variants={slideInLeft} initial="hidden" animate="visible">
              <div className="relative h-64 sm:h-80 lg:h-full">
                <img
                  alt="House"
                  src={Truck}
                  className="absolute inset-0 h-full w-full object-cover"
                />
              </div>
            </motion.div>

            <motion.div className="relative flex items-center bg-gray-100" variants={fadeInUp} initial="hidden" animate="visible">
              <span
                className="hidden lg:absolute lg:inset-y-0 lg:-left-16 lg:block lg:w-16 lg:bg-gray-100"
              ></span>

              <div className="p-8 sm:p-16 lg:p-24">
                <h2 className="text-2xl text-orange-500 font-bold sm:text-3xl">
                  At Rivas Tile & Painting, LLC,
                </h2>

                <p className="mt-4 text-1xl text-gray-600">
                  We are dedicated to providing exceptional service to our customers. We believe in acting with responsibility, integrity, honesty, and respect to enhance each client's experience. Our company values customer satisfaction above all else, and we treat our clients as part of our family. We foster a culture of innovation and creativity among our team to provide dynamic solutions for all home improvement projects. Our mission is to offer innovative solutions to achieve the best results for our customers. Our vision is to become the top provider of residential and commercial restoration, repair, and remodeling solutions in the region. Choose us for our hardworking attitude and commitment to exceeding your expectations.
                </p>

                {/* <a
                  href="/Contact"
                  className="mt-8 inline-block rounded border border-orange-500 bg-orange-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-orange-500 focus:outline-none focus:ring active:text-orange-500"
                >
                  Get in Touch
                </a> */}
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  )
}
