import React from "react";
import backsplash from "../assets/backsplash.jpeg";
import beforebath from "../assets/beforebath.jpeg";
import refinshedbath from "../assets/refinshedbath.jpg";
// import afterbath from "../assets/afterbath.jpeg";
import beforebathtile from "../assets/beforebathtile.jpeg";
import afterbathtile from "../assets/afterbathtile.jpeg";
import Cabinets from "../assets/cabniets.jpeg";
import paintedCabinets from "../assets/paintedCabinets.jpeg";
import FinishedCabniets from "../assets/FinishedCabinets.jpeg";


const Project = ({title, src, description}) => (
  <div className="flex flex-col  rounded-md overflow-hidden shadow-lg m-10 ">
    <img className="flex" src={src} alt={title} />
    <div className=" px-2 h-20 text-center pt-5">
      <div className="font-bold text-xl mb-2 text-orange-300">{title}</div>
      <p className="text-yellow-800  text-base">{description}</p>
    </div>
    <div className="w-full bg-gray-100 shadow text-gray-800 font-bold py-6 pt-6 rounded-md inline-flex items-center"></div>
  </div>
)

const Projects = () => (
  <>
  <div className=" flex flex-col justify-center items-center"> 
    <h1 className="text-3xl mt-8 text-orange-500 ">Projects that we are proud of</h1>
    <div className="lg:grid grid-cols-4 mb-4 m-0 rounded-md mx-auto text-center px-1">
      <Project 
        title="Backsplash"
        src={backsplash}
        
      />
      <Project 
        title="Before Bathroom Renovation"
        src={beforebath}
      />
      <Project 
        title="full-scale renovation"
        src={refinshedbath}
      />
      <Project 
        title="Pre-Bathroom Tile Work"
        src={beforebathtile}
      />
       <Project 
        title="Post-Bathroom Tile Work"
        src={afterbathtile}
      />
       <Project 
        title=" Pre-paint cabinet condition"
        src={Cabinets}
      /> <Project 
      title="Post-paint cabinet condition "
      src={paintedCabinets }
    /> <Project 
    title="Post-paint cabinet condition"
    src={FinishedCabniets}
  />
    </div>
  </div>
  </>
)

export default Projects;
