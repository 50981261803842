import React, { useEffect } from 'react';

const GoogleReviews = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apps.elfsight.com/p/platform.js';
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="elfsight-app-5e3dd1db-5a8a-455b-88ca-b060554670a0"></div>
  );
};

export default GoogleReviews;
