import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { useState } from "react";

export const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <header aria-label="Site Header" className="shadow-lg dark:bg-white">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex-1 md:flex md:items-center md:gap-12">
            <Link className="block text-orange-600 dark:text-orange-300" to="/">
              <img className="h-12 w-15" src={logo} alt="logo" />
            </Link>
          </div>

          <div className="hidden md:block text-orange-500 ">
            <nav className="flex space-x-12">
              <Link className="hover:text-orange-700 " to="/">Home</Link>
              <Link className="hover:text-orange-700 " to="/projects">Projects</Link>
              <Link className="hover:text-orange-700 " to="/services">Services</Link>
              <Link className="hover:text-orange-700 " to="/Reviews">Reviews</Link> 
              <Link className="hover:text-orange-700 " to="/contact">Contact</Link>
            </nav>
          </div>

          <div className="md:hidden">
            <button
              type="button"
              className="text-orange-600 dark:text-ornage-300 hover:text-ornage-900"
              onClick={() => setNavOpen(!navOpen)}
            >
              {navOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>
        </div>
      </div>

      {navOpen && (
        <div className="  bg-white md:hidden text-center fixed z-40 inset-0 h-screen w-full">
         <button
              className=" close-button block text-orange-600 hover:text-orange-900 px-6 py-4 text-right"
              onClick={() => setNavOpen(false)}
            >
              <FaTimes/>
            </button> 
            <nav className="pt-12 space-y-12 text-[2rem]  md:mt-[6rem] sm:mt-[3rem]">
            <Link className="block text-orange-600 hover:text-white-900 px-4 py-2" to="/"  onClick={() => setNavOpen(false)}>
              Home
            </Link>
            <Link className="block text-orange-600 hover:text-white-900 px-4 py-2" to="/Projects"  onClick={() => setNavOpen(false)}>
            Projects
            </Link>
            <Link className="block text-orange-600 hover:text-white-900 px-4 py-2" to="/Reviews"  onClick={() => setNavOpen(false)}>
              Reviews
            </Link>
            <Link className="block text-orange-600 hover:text-white-900 px-4 py-2" to="/services" onClick={() => setNavOpen(false)}>
              Services
            </Link>
            <Link className="block text-orange-600 hover:text-white-900 px-4 py-2" to="/contact"  onClick={() => setNavOpen(false)}>
              Contact
            </Link>
            
          </nav>
        </div>
      )}
    </header>
  );
};
 