import React from "react";
import animateScrollTo from "animated-scroll-to";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import newlogo from "../assets/newlogo.png";
// import  phone  from "../assets/icons/phone.png"
library.add(fas);

export const Footer = () => {
  const ScrollToTop = () => {
    animateScrollTo(0, {
      speed: 1000,
    });
  };
  return (
    <div className="border-box shadow-lg">
      <footer
        aria-label="Site Footer"
        class="bg-gray-100  shadow-lg lg:grid lg:grid-cols-5 "
      >
        <div class="relative block h-24 lg:col-span-2 lg:h-full">
          <img
            src={newlogo}
            alt=""
            class="absolute inset-0 object-cover w-full h-full"
          />
        </div>

        <div class="px-4 py-8 sm:px-6 lg:col-span-3 lg:px-8">
          <div class=" flex px-4 py-16 sm:px-6 lg:col-span-3 lg:px-8 justify-end">
            <div
              class="inline-block rounded-full bg-white p-2 text-black shadow transition hover:bg-gray-200 sm:p-3 lg:p-4"
              href="#MainContent"
              onClick={() => ScrollToTop()}
            >
              <span class="sr-only">Back to top</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-8 sm:grid-cols-2">
            <div>
              <p>
                <span class="text-xs tracking-wide text-gray-500 uppercase">
                  Call us
                </span>

                <div
                  href="#"
                  class="block text-2xl font-medium text-gray-900 hover:opacity-75 sm:text-3xl"
                >
                  (919) 798-9751
                </div>
              </p>

              <ul class="mt-8 space-y-1 text-sm text-gray-700">
                <li>Monday to Saturday from 8:00 AM to 5:00 PM</li>
                <li>Sunday: Closed</li>
              </ul>
              <div>
                <ul class="flex gap-6 mt-8">
                  <a
                    href="https://www.facebook.com/rivastileandpainting?mibextid=LQQJ4d"
                    rel="noreferrer"
                    target="_blank"
                    class="text-gray-700 transition hover:opacity-75"
                  >
                    <span class="sr-only">Facebook</span>
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </ul>
              </div>
            </div>

            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div>
                <p class="font-medium text-gray-900">Services</p>

                <nav aria-label="Footer Navigation - Services" class="mt-6">
                  <ul class="space-y-4 text-sm">
                    <li>
                      <Link
                        className="hover:text-orange-700 mb-4"
                        to="/Services"
                      >
                        Window Installation & Repair
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-orange-700 mb-4"
                        to="/Services"
                      >
                        INTERIOR & EXTERIOR PAINTING
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-orange-700 mb-4"
                        to="/Services"
                      >
                        CABINETS PAINTING
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-orange-700 mb-4"
                        to="/Services"
                      >
                        DRYWALL REPAIR & INSTALLATION
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-orange-700 mb-4"
                        to="/Services"
                      >
                        NATURAL STONE TILE REPAIR
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-orange-700 mb-4"
                        to="/Services"
                      >
                        KITCHEN REMODELING & BATHROOM REMODELING
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-orange-700 mb-4"
                        to="/Services"
                      >
                        ELECTRICAL
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-orange-700 mb-4"
                        to="/Services"
                      >
                        PLUMBING
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover:text-orange-700 mb-4"
                        to="/Services"
                      >
                        TILE INSTALLATION & REPAIR
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div>
                <h1 class="font-medium text-gray-900">Company</h1>

                <nav aria-label="Footer Navigation - Company" class="mt-6">
                  <ul class="space-y-4 text-sm">
                    <p class="text-orange-600 transition hover:opacity-75">
                      For Your Comfort, We are Bilingual We Have more than 14
                      Years of Experience
                    </p>

                    <br />

                    <p class="text-orange-600 transition hover:opacity-75">
                      We Are Licensed & Insured,
                      <br />
                      Payment Method: Cash, Checks and Credit/Debit Cards
                    </p>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div class="pt-12 mt-12 border-t border-gray-100">
            <div class="sm:flex sm:items-center sm:justify-between">
              <nav aria-label="Footer Navigation - Support">
                <ul class="flex flex-wrap gap-4 text-xs">
                  <li>
                    <p class="text-gray-500 transition hover:opacity-75">
                      Terms & Conditions
                    </p>
                  </li>

                  <li>
                    <div class="text-gray-500 transition hover:opacity-75">
                      Privacy Policy
                    </div>
                  </li>

                  <li>
                    <div class="text-gray-500 transition hover:opacity-75">
                      Cookies
                    </div>
                  </li>
                </ul>
              </nav>

              <p class="mt-8 text-xs text-gray-500 sm:mt-0">
                &copy; 2022. Rivas Tile & Painting, LLC. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
