import React, { useState } from "react";
import bathtub from "../assets/bathtub.jpeg";
import hardwood from "../assets/hardwood.jpeg";
import hardwoodk from "../assets/hardwoodk.jpeg";


const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [{ url: bathtub }, { url: hardwood }, { url: hardwoodk }];

  const handleSwipe = (e) => {
    if (e.deltaX > 0) {
      setCurrentSlide((currentSlide + 1) % slides.length);
    } else if (e.deltaX < 0) {
      setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
    }
  };



  return (
    <div onTouchMove={handleSwipe}>
      <img
        className="Carouselimg  "
        src={slides[currentSlide].url}
        alt={`Slide ${currentSlide + 1}`} 
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
      />
      <div className=" mt-4 rounded border-dashed border-2 border-orange-500 text-black  px-12 py-2 ">Current slide: {currentSlide + 1}</div>
      <button className="inline-block rounded border border-orange-600 px-12 py-3 text-sm font-medium text-black-600 hover:bg-orange-600 hover:text-white focus:outline-none mt-4"
        onClick={() => setCurrentSlide((currentSlide + 1) % slides.length)}
      >
        Next
      </button>
    </div>
  );
};

export default Carousel;
