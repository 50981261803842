import React from "react";

const  Aboutus = () => {
return (
<div>
<section className="bg-gray-900 text-white">
<div className="max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
<div className="max-w-xl">
<h2 className="text-3xl font-bold sm:text-4xl">
At Rivas Tile & Painting, LLC,
</h2>

<p className="mt-4 text-gray-300">
          we are dedicated to providing exceptional service to our
          customers. We believe in acting with responsibility, integrity,
          honesty, and respect to enhance each client's experience.
        </p>
      </div>

      <div className="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
        <div className="flex items-start">
          <span className="flex-shrink-0 rounded-lg bg-gray-800 p-4">
          <svg className="h-5 w-5" fill="none" stroke="currentColor"

              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
              <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
              ></path>
            </svg>
          </span>

          <div className="ml-4">
            <h2 className="text-lg font-bold">company values </h2>

            <p className="mt-1 text-sm text-gray-300">
              Our company values customer satisfaction above all else, and
              we treat our clients as part of our family.
            </p>
          </div>
        </div>

        <div className="flex items-start">
        <span className="flex-shrink-0 rounded-lg bg-gray-800 p-4">
  <svg
    className="h-5 w-5"
    fill="none"
    stroke="current"
  />
  <div className="ml-4">
    <h2 className="text-lg font-bold">Lorem, ipsum dolor.</h2>
  </div>
</span>

              <p className="mt-1 text-sm text-gray-300">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
                cumque tempore est ab possimus quisquam reiciendis tempora
                animi! Quaerat, saepe?
              </p>
            </div>
          </div>
          </div>
          </section>
          </div>
);
};

export default Aboutus;