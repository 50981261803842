import React from 'react'
import {Content} from "../components/Content"
// import Carousel from './components/Carousel';
import {Companyinfo}from "../components/Companyinfo"
import { ChoosingUsInfo } from '../components/ChoosingUsInfo'



function Home() {
  return (
    <div>
<Content/>
<Companyinfo/>
<ChoosingUsInfo/>

    </div>
  )
}

export default Home