import React from 'react'

import { ContactUs } from '../validator/ContactUs'

export default function Contact() {
  return (

  <> 
  
  <section className="lg:bg-gray-100 lg:my-8 lg:py-8 lg:mx-8">

    <div className="mx-auto  px-4 py-16 sm:px-6 lg:px-8">
      <p className='flex items-center justify-center text-4xl pb-12 text-'>Contact Us </p>
      <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
        <div className="lg:col-span-2 lg:py-12">
          <p className="max-w-xl text-lg text-orange-600">
          Thank you for considering Rivas Tile & Painting, LLC for your home or business improvement needs. Please fill out the form below to contact us for a free estimate. We will respond to your inquiry as soon as possible.
          </p>
  
  
        </div>
<ContactUs/>

      </div>
    </div>
  </section>
  </>
  )
}

