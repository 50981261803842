import React, { useState } from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

export const ContactUs = () => {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2w9m6yy",
        "rivas-tpllc",
        form.current,
        "zet4oJ9U0lmapwdOY"
      )
      .then(
        (result) => {
          setMessageSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12 sm:w-full">
      <form
        id="contact-form"
        className="space-y-4 sm:w-full"
        action=""
        ref={form}
        onSubmit={sendEmail}
      >
        <div>
          <label className="sr-only" for="name">
            Name
          </label>
          <input
            className="w-full rounded-lg border-2  border-gray-200 border-2  p-3 text-sm"
            placeholder="Name"
            type="text"
            name="from_name"
            id="from_name"
          />
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div>
            <label className="sr-only" for="email">
              Email
            </label>
            <input
              className="w-full rounded-lg border-2  border-gray-200 p-3 text-sm"
              placeholder="Email address"
              type="email"
              name="email_id"
              id="email_id"
            />
          </div>

          <div>
            <label className="sr-only" for="phone">
              Phone
            </label>
            <input
              className="w-full rounded-lg border-2  border-gray-200 p-3 text-sm"
              placeholder="Phone Number"
              type="tel"
              name="phone_id"
              id="phone_id"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-1"></div>

        <label className="sr-only" for="message">
          Message
        </label>
        <textarea
          className="w-full rounded-lg border-2  border-gray-200 p-3 text-sm"
          placeholder="Message"
          rows="8"
          name="message"
          id="message"
        ></textarea>

        <div class="mt-4">
          <button
            type="submit"
            value="send"
            class="inline-flex w-full items-center justify-center  rounded border border-orange-600 bg-orange-600 px-8 py-3 text-white hover:bg-transparent hover:text-orange-600 focus:outline-none focus:ring active:text--500"
          >
            <span class="font-medium"> Send Enquiry </span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ml-3 h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </button>
        </div>
      </form>
      {messageSent && <p className="greenmsg">Your message has been sent</p>}
    </div>
  );
};
