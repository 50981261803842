import React from "react";

import window from "../assets/icons/window.png";
import paint from "../assets/icons/paint.png";
import cabinets from "../assets/icons/cabinets.png";
import drywall from "../assets/icons/drywall.png";
import stone from "../assets/icons/stone.png";
import bathroom from "../assets/icons/bathroom.png";
import electrical from "../assets/icons/electrical.png";
import plumbing from "../assets/icons/plumbing.png";
import tile from "../assets/icons/tile.png";
import { motion } from "framer-motion";
function Services() {
  const jump = {
    animate: {
      y: ["0%", "-10%", "0%"],
      transition: {
        duration: 3,
        repeat: Infinity,
      },
    },
  };
  return (
    <>
      <h1 className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8  text-center text-3xl text-orange-500">
        Our Services{" "}
      </h1>
      <div className="px-3 md:lg:xl:px-40   border-t border-b py-20 bg-opacity-10">
        <div className="grid grid-cols-1 md:lg:xl:grid-cols-3 group bg-white shadow-xl shadow-neutral-100 border ">
        <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
        <motion.span
          className="p-5 rounded-full bg-red-500 text-white shadow-lg shadow-red-200"
          variants={jump}
          initial="animate"
          animate="animate"
        >
          <img className="icons" src={window} alt="" />
        </motion.span>
        <p className="text-xl font-medium text-slate-700 mt-3">
          WINDOW INSTALLATION & REPAIR
        </p>
        <p className="mt-2 text-sm text-slate-500">
          Enhance your home's aesthetic with our expert window installation
          and repair services. Our specialists will ensure that your windows
          complement your home's façade and provide a modern, appealing
          look. Whether you need new window installation or repairs for
          existing damage, we have the solution.
        </p>
      </div>
      <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
        <motion.span
          className="p-5 rounded-full bg-orange-500 text-white shadow-lg shadow-orange-200"
          variants={jump}
          initial="animate"
          animate="animate"
        >
          <img className="icons" src={paint} alt="" />
        </motion.span>
        <p className="text-xl font-medium text-slate-700 mt-3">
          INTERIOR & EXTERIOR PAINTING
        </p>
        <p className="mt-2 text-sm text-slate-500">
          Get the best painting service for your home or business with Rivas
          Tile & Painting, LLC. No need to search for the right company, we
          offer top-notch interior and exterior painting services. Contact
          us now for a free estimate and let us handle your painting project
          while you focus on what's important.
        </p>
      </div>
      <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
        <motion.span
          className="p-5 rounded-full bg-yellow-500 text-white shadow-lg shadow-yellow-200"
          variants={jump}
          initial="animate"
          animate="animate"
        >
          <img className="icons" src={cabinets} alt="" />
        </motion.span>
        <p className="text-xl font-medium text-slate-700 mt-3">
          CABINETS PAINTING
        </p>
        <p className="mt-2 text-sm text-slate-500">
          Painting your cabinets may not be as easy as it looks. Luckily,
          you count with Rivas Tile & Painting, LLC to complete your cabinet
          painting projects with the highest quality. Our experts are ready
          to exceed your expectations by giving your cabinets an incredible
          face-lift with special paint. Call us now to take advantage of the
          best services in town.
        </p>
      </div>
          <div className="p-10 flex flex-col items-center text-center group border border-gray-200 ">
        <motion.span
          className="p-5 rounded-full bg-red-500 text-white shadow-lg shadow-red-200"
          variants={jump}
          initial="animate"
          animate="animate"
        >
            <span className="rounded-full  text-white shadow-lg shadow-red-200 ">
              <img className="icons" src={drywall} alt="" />
            </span>
            </motion.span>
            <p className="text-xl font-medium text-slate-700 mt-3">
              DRYWALL REPAIR & INSTALLATION
            </p>
            <p className="mt-2 text-sm text-slate-500">
              Ensure top-quality finishes for your construction project with
              Rivas Tile & Painting, LLC's expert drywall repair and
              installation services. We specialize in both residential and
              commercial projects. Contact us now to experience the best drywall
              services in town.
            </p>
          </div>
          <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r hover:bg-slate-50 cursor-pointer">
        <motion.span
          className="p-5 rounded-full bg-teal-500 text-white shadow-lg shadow-teal-200"
          variants={jump}
          initial="animate"
          animate="animate"
        >
          <img className="icons" src={stone} alt="" />
        </motion.span>
        <p className="text-xl font-medium text-slate-700 mt-3">
          NATURAL STONE TILE REPAIR
        </p>
        <p className="mt-2 text-sm text-slate-500">
          Enhance the aesthetic of your space with professional stone
          installation services from Rivas Tile & Painting, LLC. Whether
          it's for a residential or commercial project, our experts have the
          skills and experience to deliver the highest quality finish. From
          countertops to fireplace surrounds, we have the solution for all
          your stone installation needs. Contact us now to schedule a
          consultation.
        </p>
      </div>

      <div className="p-10 flex flex-col items-center text-center group hover:bg-slate-50 cursor-pointer">
        <motion.span
          className="p-5 rounded-full bg-indigo-500 text-white shadow-lg shadow-indigo-200"
          variants={jump}
          initial="animate"
          animate="animate"
        >
          <img className="icons" src={bathroom} alt="" />
        </motion.span>
        <p className="text-xl font-medium text-slate-700 mt-3">
          KITCHEN REMODELING & BATHROOM REMODELING
        </p>
        <p className="mt-2 text-sm text-slate-500">
          Are you ready to take your kitchen and bathroom to the next level?
          Look no further than Rivas Tile & Painting, LLC. Our team of
          experts specialize in transforming these spaces with a friendly
          and innovative approach. From modern designs to expert
          installations, we have the solution to elevate the aesthetic and
          functionality of your property. Don't settle for less, contact us
          now for a free estimate and experience the best in kitchen and
          bathroom remodeling services.
        </p>
      </div>

      <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer border-t border-1">
        <motion.span
          className="p-5 rounded-full bg-red-500 text-white shadow-lg shadow-red-200"
          variants={jump}
          initial="animate"
          animate="animate"
        >
          <img className="icons" src={electrical} alt="" />
        </motion.span>
        <p className="text-xl font-medium text-slate-700 mt-3">
          ELECTRICAL
        </p>
        <p className="mt-2 text-sm text-slate-500">
          Ensure the safety and efficiency of your electrical systems with
          professional services from Rivas Tile & Painting, LLC. Whether
          it's new installation, repair or upgrade, our team of experts can
          handle all your electrical needs. From residential to commercial
          projects, we have the skills and experience to deliver top-quality
          results. Don't risk electrical problems, contact us now for a free
          estimate and experience the best electrical services in town.
        </p>
      </div>

      <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer border-t border-1">
        <motion.span
          className="p-5 rounded-full bg-blue-500 text-white shadow-lg shadow-blue-200"
          variants={jump}
          initial="animate"
          animate="animate"
        >
          <img className="icons" src={plumbing} alt="" />
        </motion.span>
        <p className="text-xl font-medium text-slate-700 mt-3">PLUMBING</p>
        <p className="mt-2 text-sm text-slate-500">
          Ensure the proper functioning of your plumbing systems with
          professional services from Rivas Tile & Painting, LLC. Our team of
          experts specialize in providing top-notch installation, repair,
          and maintenance services for all types of plumbing systems. From
          fixing leaks to installing new fixtures, we have the solution for
          all your plumbing needs. Contact us now for a free estimate and
          experience the best plumbing services in town.
        </p>
      </div>

          <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer border-t border-1">
          <motion.span
          className="p-5 rounded-full bg-green-500 text-white shadow-lg shadow-green-200"
          variants={jump}
          initial="animate"
          animate="animate"
          >
            
          
              {" "}
              <img className="icons" src={tile} alt="" />
          
            </motion.span>
            <p className="text-xl font-medium text-slate-700 mt-3">
              TILE INSTALLATION & REPAIR
            </p>
            <p className="mt-2 text-sm text-slate-500">
              Unlock the full potential of your space with professional tile
              installation services from Rivas Tile & Painting, LLC. Our team of
              experts, with over 14 years of experience, will provide you with
              the highest quality ceramic and porcelain tile options to elevate
              the aesthetic of your property. Whether it's a new installation or
              a remodel, we have the solution for all your tile needs. Contact
              us now for a free estimate and experience the best tile services
              in town.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
