import React from 'react';
import Carousel from './Carousel';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';

export const Content = () => {
  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };
  const slideInRight = {
    hidden: { x: 100, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
  };

  return (
    <div>
      <section className="text-center">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 text-center shadow-lg">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <motion.div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full" variants={slideInRight} initial="hidden" animate="visible">
              <Carousel />
            </motion.div>

            <motion.div className="lg:py-24" variants={fadeIn} initial="hidden" animate="visible">
              <h2 className="text-3xl font-bold sm:text-4xl text-orange-400">Looking for a reliable and professional contractor to help you with your home or business improvement projects?</h2>

              <p className="mt-4 text-gray-600">
                Look no further. Rivas Tile & Painting, LLC offers professional and detailed services with over 14 years of experience in residential and commercial locations. Trust us for high-quality services and satisfied customers. Contact us in Raleigh, North Carolina for unbeatable results.
              </p>
              <br />
              <h3>
                Take the first step towards a successful project by preparing yourself and requesting a complimentary estimate. To ensure your convenience, our team is proficient in both English and Spanish languages.
              </h3>
              <Link to="/Contact" className="mt-8 inline-flex items-center rounded border border-orange-600 bg-orange-600 px-8 py-3 text-white hover:bg-transparent hover:text-orange-600 focus:outline-none focus:ring active:text--500">
                <span className="text-sm font-medium"> Begin your complimentary estimate </span>

                <svg
                  className="ml-3 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </Link>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
}
