import React from 'react'
import { motion } from 'framer-motion';
import Kitchen from "../assets/Kitchen.jpeg"
import Garage from "../assets/Garage.jpeg"
import Tile from "../assets/Tile.jpeg"

export const ChoosingUsInfo = () => {
  // Animation variants
  const slideUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 2.5} },
  };

  return (
    <div className="">
      <h1 className="text-center text-4xl font-bold text-black py-5">Why Choose Us?</h1>

      <section className="flex flex-wrap justify-around px-4 py-4 rounded-lg mb-10">
        {/* card 1 */}
        <motion.article className="w-full sm:w-1/4 shadow-xl bg-white transition hover:shadow-xl px-4" variants={slideUp} initial="hidden" animate="visible">
          <div className="py-4 text-center flex justify-center">
            <time
              dateTime="2022-10-10"
              className="flex items-center justify-between gap-4 text-xs font-bold uppercase text-orange-500"
            >
              <span className="text-center text-2xl">Experience</span>
            </time>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <img className='rounded-lg flex justify-center m-auto' src={Kitchen} alt="" />
            <div className="border-l border-width:10px p-4 sm:border-l-transparent sm:p-6">
              <p className="mt-2 text-1xl text-center leading-relaxed text-gray-700">
                At Rivas Tile & Painting, LLC, we understand the importance of having a home or business that not only looks great but also functions at its best. That's why we are dedicated to providing high-quality and innovative solutions for all your home improvement projects.
              </p>
            </div>
          </div>
        </motion.article>
        {/* Card 2  */}
        <motion.article className="w-full sm:w-1/4 shadow-xl bg-white transition hover:shadow-xl px-4" variants={slideUp} initial="hidden" animate="visible">
          <div className="py-4 text-center flex justify-center">
            <time
              dateTime="2022-10-10"
              className="flex items-center justify-between gap-4 text-xs font-bold uppercase text-orange-500"
            >
              <h1 className='text-2xl text-center'>Quality work</h1>
            </time>
          </div>
          <img className='rounded-lg mx-auto my-4' src={Garage} alt="" />
          <div className="flex flex-1 flex-col justify-between">
            <div className="border-l border-width:10px p-4 sm:border-l-transparent sm:p-6">
              <p className="mt-2 text-1xl text-center leading-relaxed text-gray-700">
                Our experienced professionals deliver exceptional results in tile installation, painting, and remodeling. We use high-quality materials and the latest techniques for attention to detail to ensure
your home or business looks its best. Our goal is to exceed expectations with competitive pricing and flexible scheduling.
              </p>
            </div>
          </div>
        </motion.article>
            {/* card 3  */}
            <motion.article className="w-full sm:w-1/4 shadow-xl bg-white transition hover:shadow-xl px-4" variants={slideUp} initial="hidden" animate="visible">
          <div className="py-4 text-center flex justify-center">
            <time
              dateTime="2022-10-10"
              className="flex items-center justify-between gap-4 text-xs font-bold uppercase text-orange-500"
            >
              <h1 className='text-2xl'>CUSTOMER CARE</h1>
            </time>
          </div>
          <img className='rounded-lg flex justify-center m-auto' src={Tile} alt="" />
          <div className="flex flex-1 flex-col justify-between">
            <div className="border-l border-width:10px p-4 sm:border-l-transparent sm:p-6">
              <p className="mt-2 text-1xl text-center leading-relaxed text-gray-700">
                Choosing Rivas Tile & Painting, LLC, means you are choosing a company that is dedicated to providing you with the best possible service, and one that will work hard to meet your needs and exceed your expectations. We are confident that you will be satisfied with the work we do, and we look forward to the opportunity to work with you.
              </p>
            </div>
          </div>
        </motion.article>
      </section>
    </div>
  )
};

